<template>
    <!-- Configuración Datatable: https://www.primefaces.org/primevue/showcase/#/datatable -->
    <DataTable :value="datos" v-model:expandedRows="expandedRows" :lazy="true" stripedRows removableSort
        :loading="loading" responsiveLayout="scroll" :paginator="true" :rows="numDatos" v-model:filters="filters"
        ref="tabla" :totalRecords="Number(total)" :page="page" :CurrentPageReport="currentPage" :first="first"
        :last="last"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5,15,25,50]" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
        @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row" csvSeparator=";"
        exportFilename="datos" :globalFilterFields="filtrosBuscador" :class="clase">
        <template #header>

            <div v-if="!botones" class="col-md-12">
                <Button icon="pi pi-plus-circle" v-if="botoncrear!=1" class="p-button-primary p-button-sm m-2"
                    :label="crears" @click="crear" />
                <!-- <Button icon="pi pi-refresh" class="p-button-info p-button-sm m-2" :label="refrescars"
                    @click="refrescar" />
                <Button icon="pi pi-external-link" class="p-button-secondary p-button-sm m-2" :label="exportars"
                    @click="exportCSV($event)" />-->
            </div>
        </template>
        <template #empty>
            No hay datos.
        </template>
        <template #loading>
            Cargando datos. Por favor, espere.
        </template>
        <Dialog :style="{ width: '25rem' }" header="Explicación satisfacción" dismissableMask modal
            v-model:visible="mostrarExplicacion">
            <p>{{ explicacionSatis }}</p>
        </Dialog>
        <Column v-for="col in columnas" :filterField="col.field" :key="col.field" :header="col.header"
            :field="col.field" :sortable="col.sortable" filterMatchMode="contains">
            <template #header>
                <div @click="console.log('coll', col)" v-if="col.checkbox"
                    style="display: flex; justify-content: center; width: 100%;">
                    <Checkbox v-model="checkboxGeneral" :binary="true" />
                </div>
                <div v-if="col.nombrefilt">
                    <input type="text" class="form-control" @keydown="onKeydownHandler" v-model="nombrefiltrado"
                        placeholder="Nombre" style="width: 300px;" @input="() => console.log(nombrefiltrado)">
                </div>
                <div v-if="col.tramifilt">
                    <select class="form-control" v-model="tramitadorSeleccionado">
                        <option value="">Tramitador</option>
                        <option v-for="tram in tramitadores" :key="tram.id" :value="tram.id">{{ tram.nombre }}</option>
                    </select>
                </div>
                <div v-if="col.espependfilt">
                    <Dropdown scrollHeight="600px" v-model="pendienteselec" optionLabel="nombre" :options="estadospend"
                        optionGroupChildren="items" optionGroupLabel="label" optionValue="id_estado"
                        style="width: 200px;" placeholder="Pendiente de" showClear />
                </div>
                <div v-if="col.field=='dias_sin_actualizar'">
                    <span :title="col.title">{{ $t('general.diassinactualizar') }}</span>
                </div>
            </template>
            <template v-if="col.checkbox" #footer>
                <button v-if="solicitudes" class="btn btn-sm btn-light" style="border: 1px solid grey;"
                    @click="$emit('eliminarSeleccionadas')">Eliminar seleccionadas</button>
                <button v-else class="btn btn-sm btn-light" style="border: 1px solid grey;"
                    @click="$emit('revisarSeleccionados')">Revisar seleccionados</button>
            </template>
            <template #body="slotProps" v-if="esColumnaProveedor(col)">
                <div class="card card-widget widget-user-2">
                    <div class="widget-user-header bg-olive" style="padding: 0.5rem;">
                        <div class="widget-user-image">
                            <i class="fas fa-user-cog" @click="proveedorPulsado(slotProps.data[col.id])" style="font-size:xxx-large;position:absolute; cursor: pointer;"></i>
                        </div>
                        <h5 class="widget-user-username" style="font-size:20px">{{slotProps.data[col.field]}}</h5>
                        <h5 class="widget-user-desc" style="font-size:15px">{{ $t('general.proveedor') }}</h5>
                    </div>
                    <div class="card-footer p-0 bg-olive">
                        <div class="row ">
                            <div class="col-md-4 ">
                                <button type="button" v-on:click='llamar(slotProps.data[col.telefono_proveedor])' v-if="slotProps.data[col.telefono_proveedor]"
                                    class="btn btn-block btn-warning btn-xs" title="Descripción teléfono 1">{{slotProps.data[col.telefono_proveedor]}}</button>
                            </div>
                            <div class="col-md-6 ">

                            </div>
                            <div class="col-md-2">
                                <button type="button" v-if="slotProps.data[col.email_proveedor]" v-on:click='email(slotProps.data[col.id])'
                                    class="btn btn-block btn-warning btn-xs" title="Email">
                                    <i class="fas fa-at"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #body="slotProps" v-else-if="esColumnaPersonalizada(col)">                
                <div v-if="col.componente" class="componente">
                    <component :is="componenteDinamico(col.componente.ruta)" :props="col.componente.props"
                        :datos="slotProps.data" @[col.componente.evento]="col.componente.listener">

                    </component>
                </div>
                <div v-else-if="col.formato">
                    {{col.formato(slotProps.data[col.field])}}
                </div>
                <div v-else-if="col.acciones && !slotProps.data.datos_basicos" class="acciones">
                    <botones-acciones ref="accionesref" :acciones="col.acciones" :nombredos="slotProps.data[col.field]"
                        :datos="slotProps.data"></botones-acciones>
                </div>
                <div v-else-if="col.acciones && slotProps.data.datos_basicos" class="acciones">
                    <div @mouseenter="infoevento($event, slotProps.field, slotProps.data)" :class="col.field"
                        style="position:relative"> <!---->
                        <b title="Urgente" style="color: red"
                            v-if="col.header == 'Expediente' && slotProps.data.datos_basicos.urgente == '1'">!&nbsp;</b>
                        <b title="Reclamado" style="color: orange"
                            v-if="col.header == 'Expediente' && slotProps.data.datos_basicos.reclamacion == '1'">!&nbsp;</b>
                        <b title="Reaperturados" style="color: red"
                            v-if="col.header == 'Expediente' && slotProps.data.datos_basicos.reasignado == '1'">R&nbsp;</b>
                        <b title="Nuevo" style="color: green;"
                            v-if="col.header == 'Expediente' && slotProps.data.datos_basicos.tramitado != '1'">N&nbsp;</b>
                        <div v-if="col.field == 'icono' && slotProps.data.satisfaccion_ia_servicio"
                            style="display: flex; align-items: center; column-gap: 10px;">
                            <botones-acciones :acciones="col.acciones" :nombredos="slotProps.data[col.field]"
                                :datos="slotProps.data"></botones-acciones>
                            <div style="display: grid">
                                <div class="d-flex justify-content-center satisf" @click="explicacion(slotProps.data)"
                                    @mouseover="showDialog(slotProps.data.idservicios)"
                                    @mouseleave="hideDialog(slotProps.data.idservicios)">
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '4'"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                            width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                            <defs>
                                                <clipPath id="__lottie_element_2">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clipPath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_2)">
                                                <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></div>
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '5'"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                            width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                            <defs>
                                                <clipPath id="__lottie_element_2">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clipPath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_2)">
                                                <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(13,170,93)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></div>
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '3'"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                            width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                            <defs>
                                                <clipPath id="__lottie_element_22">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clipPath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_22)">
                                                <g transform="matrix(1,0,0,1,99.739990234375,149.75)" opacity="1"
                                                    style="display: block;">
                                                    <g opacity="1" transform="matrix(1,0,0,1,500.260009765625,500.25)">
                                                        <path fill="rgb(153,205,155)" fill-opacity="1"
                                                            d=" M0,-500 C134.2270050048828,-500 256.12298583984375,-447.0799865722656 345.95098876953125,-360.9700012207031 C440.8909912109375,-269.9599914550781 500.010009765625,-141.87600708007812 500.010009765625,-0.009999999776482582 C500.010009765625,138.86500549316406 443.364990234375,264.5320129394531 351.9259948730469,355.1440124511719 C261.56500244140625,444.68798828125 137.2270050048828,500 0,500 C-136.88299560546875,500 -260.9410095214844,444.96600341796875 -351.2449951171875,355.8179931640625 C-443.0840148925781,265.1549987792969 -500.010009765625,139.2100067138672 -500.010009765625,-0.009999999776482582 C-500.010009765625,-140.22500610351562 -442.2590026855469,-266.97698974609375 -349.2510070800781,-357.77801513671875 C-259.1189880371094,-445.77099609375 -135.8780059814453,-500 0,-500z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.50299072265625,473.343017578125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.972999572753906,-17.56999969482422 C68.51899719238281,19.485000610351562 51.09299850463867,57.38600158691406 19.062999725341797,67.08799743652344 C-12.944999694824219,76.78900146484375 -47.44599914550781,54.604000091552734 -57.992000579833984,17.548999786376953 C-68.51799774169922,-19.506999969482422 -51.112998962402344,-57.404998779296875 -19.083999633789062,-67.10700225830078 C12.925999641418457,-76.78800201416016 47.426998138427734,-54.625999450683594 57.972999572753906,-17.56999969482422z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.1220092773438,473.343017578125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.97200012207031,-17.56999969482422 C-68.51799774169922,19.485000610351562 -51.09199905395508,57.38600158691406 -19.062999725341797,67.08799743652344 C12.946000099182129,76.78900146484375 47.446998596191406,54.604000091552734 57.99399948120117,17.548999786376953 C68.51899719238281,-19.506999969482422 51.112998962402344,-57.404998779296875 19.083999633789062,-67.10700225830078 C-12.925000190734863,-76.78800201416016 -47.42599868774414,-54.625999450683594 -57.97200012207031,-17.56999969482422z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.909089982509613,0,0,0.909089982509613,600.001708984375,714.9097290039062)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(153,205,155)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.909089982509613,0,0,0.909089982509613,870.9087524414062,714.9097290039062)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(153,205,155)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,404.7409973144531,755.72998046875)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,194.54200744628906,47.52399826049805)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-191.01400756835938,-46.43899917602539 C-192.64599609375,-47.27399826049805 -194.29200744628906,-45.32500076293945 -193.1699981689453,-43.875 C-157.46600341796875,2.246999979019165 -84.44200134277344,47.27299880981445 0.027000000700354576,47.27299880981445 C84.41999816894531,47.27299880981445 157.41799926757812,2.309000015258789 193.14999389648438,-43.779998779296875 C194.29200744628906,-45.250999450683594 192.6199951171875,-47.231998443603516 190.96200561523438,-46.382999420166016 C141.80499267578125,-21.256000518798828 74.4260025024414,0.9279999732971191 0.027000000700354576,0.9279999732971191 C-74.42500305175781,0.9279999732971191 -141.8489990234375,-21.290000915527344 -191.01400756835938,-46.43899917602539z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></div>
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '2'"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                            width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                            <defs>
                                                <clipPath id="__lottie_element_42">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clipPath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_42)">
                                                <g transform="matrix(1,0,0,1,99.9949951171875,150.0050048828125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,500.0050048828125,499.9949951171875)">
                                                        <path fill="rgb(239,158,161)" fill-opacity="1"
                                                            d=" M0,-499.7449951171875 C146.26400756835938,-499.7449951171875 277.87701416015625,-436.8760070800781 369.2799987792969,-336.6929931640625 C450.3240051269531,-247.86300659179688 499.7550048828125,-129.697998046875 499.7550048828125,-0.009999999776482582 C499.7550048828125,137.9759979248047 443.8039855957031,262.91400146484375 353.3590087890625,353.35699462890625 C262.91400146484375,443.79901123046875 137.9759979248047,499.7449951171875 0,499.7449951171875 C-145.9739990234375,499.7449951171875 -277.3550109863281,437.125 -368.7359924316406,337.2850036621094 C-450.1029968261719,248.38600158691406 -499.7550048828125,129.9770050048828 -499.7550048828125,-0.009999999776482582 C-499.7550048828125,-142.08700561523438 -440.4289855957031,-270.3349914550781 -345.2040100097656,-361.3320007324219 C-255.4759979248047,-447.07598876953125 -133.875,-499.7449951171875 0,-499.7449951171875z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,404.875,753.0499877929688)" opacity="1"
                                                    style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,194.4429931640625,47.499000549316406)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-0.02800000086426735,-47.249000549316406 C-84.3759994506836,-47.249000549316406 -157.33799743652344,-2.308000087738037 -193.052001953125,43.775001525878906 C-194.1929931640625,45.24700164794922 -192.52200317382812,47.22600173950195 -190.86399841308594,46.37799835205078 C-141.73399353027344,21.246000289916992 -74.38800048828125,-0.9279999732971191 -0.02800000086426735,-0.9279999732971191 C74.38600158691406,-0.9279999732971191 141.7760009765625,21.277999877929688 190.9149932861328,46.415000915527344 C192.5469970703125,47.249000549316406 194.1929931640625,45.301998138427734 193.0709991455078,43.85300064086914 C157.38499450683594,-2.244999885559082 84.39900207519531,-47.249000549316406 -0.02800000086426735,-47.249000549316406z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.5929870605469,473.41400146484375)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.73300170898438,76.98899841308594)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.94300079345703,17.551000595092773 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.073999404907227,-67.06300354003906 C-12.939000129699707,-76.73899841308594 -47.42300033569336,-54.58700180053711 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.486000061035156 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.73899841308594 47.402000427246094,54.58700180053711 57.94300079345703,17.551000595092773z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.06396484375,473.41400146484375)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.73400115966797,76.98899841308594)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.8357543945312,436.15179443359375)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(239,158,161)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437133789062,436.15179443359375)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(239,158,161)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></div>
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '1'"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                            width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                            <defs>
                                                <clipPath id="__lottie_element_62">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clipPath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_62)">
                                                <g transform="matrix(1,0,0,1,100.0050048828125,150.0050048828125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,0.996999979019165,499.9949951171875,499.9949951171875)">
                                                        <path fill="rgb(226,78,103)" fill-opacity="1"
                                                            d=" M0.009999999776482582,-499.7449951171875 C136.44200134277344,-499.7449951171875 260.1210021972656,-445.0450134277344 350.30999755859375,-356.3840026855469 C442.5400085449219,-265.7149963378906 499.7449951171875,-139.531005859375 499.7449951171875,-0.009999999776482582 C499.7449951171875,136.48800659179688 444.99798583984375,260.2200012207031 356.2720031738281,350.4200134277344 C265.61199951171875,442.58599853515625 139.47300720214844,499.7449951171875 0.009999999776482582,499.7449951171875 C-149.1580047607422,499.7449951171875 -283.0889892578125,434.35400390625 -374.6679992675781,330.6759948730469 C-452.5039978027344,242.55799865722656 -499.7449951171875,126.78099822998047 -499.7449951171875,-0.009999999776482582 C-499.7449951171875,-128.78599548339844 -451.0060119628906,-246.2010040283203 -370.9739990234375,-334.8139953613281 C-279.5069885253906,-436.0889892578125 -147.16600036621094,-499.7449951171875 0.009999999776482582,-499.7449951171875z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,402.7510070800781,681.197998046875)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,220.28799438476562,107.28700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M153.7530059814453,105.4010009765625 C154.29600524902344,106.81600189208984 156.10800170898438,107.03600311279297 156.77699279785156,105.6760025024414 C167.49000549316406,83.89299774169922 220.03799438476562,-38.46200180053711 70.0999984741211,-76.89299774169922 C-48.470001220703125,-107.03700256347656 -194.26400756835938,-7.196000099182129 -219.65699768066406,103.81700134277344 C-220.03799438476562,105.48200225830078 -217.9499969482422,106.59200286865234 -216.64700317382812,105.48799896240234 C-49.48699951171875,-36.2400016784668 118.53099822998047,13.829999923706055 153.7530059814453,105.4010009765625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.60699462890625,473.41900634765625)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.73300170898438,77.01000213623047)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.94300079345703,17.549999237060547 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.05299949645996,-67.06199645996094 C-12.939000129699707,-76.75900268554688 -47.42300033569336,-54.58599853515625 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.48699951171875 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.75900268554688 47.402000427246094,54.58700180053711 57.94300079345703,17.549999237060547z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.0809936523438,473.41302490234375)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,69.39299774169922,76.98899841308594)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                                                        </path>
                                                    </g>
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.73300170898438,77.01399993896484)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.94200134277344,17.549999237060547 C-68.48300170898438,-19.486000061035156 -51.066001892089844,-57.36600112915039 -19.073999404907227,-67.06199645996094 C12.939000129699707,-76.75900268554688 47.422000885009766,-54.58599853515625 57.96200180053711,-17.549999237060547 C68.48300170898438,19.48699951171875 51.08599853515625,57.36600112915039 19.073999404907227,67.06300354003906 C-12.9399995803833,76.75900268554688 -47.402000427246094,54.58700180053711 -57.94200134277344,17.549999237060547z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437744140625,436.1528015136719)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(226,78,103)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.834716796875,436.1528015136719)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill="rgb(226,78,103)" fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg></div>
                                    <div title="Satisfacción del Expediente"
                                        v-if="slotProps.data.satisfaccion_ia_servicio == '0' && slotProps.data.valorado_satisfaccion_ia_servicio == '1'">
                                        <svg viewBox="0 0 1200 1300"
                                            style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <clippath id="__lottie_element_2">
                                                    <rect width="1200" height="1300" x="0" y="0"></rect>
                                                </clippath>
                                            </defs>
                                            <g clip-path="url(#__lottie_element_2)">
                                                <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                                                        <path fill-opacity="1"
                                                            d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z"
                                                            style="fill: rgb(108, 117, 125);"></path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                        <path fill="rgb(0,0,0)" fill-opacity="1"
                                                            d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                                                        </path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                            style="fill: rgb(108, 117, 125);"></path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                            style="fill: rgb(108, 117, 125);"></path>
                                                    </g>
                                                </g>
                                                <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                                                    opacity="1" style="display: block;">
                                                    <g opacity="1"
                                                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                        <path fill-opacity="1"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                            style="fill: rgb(108, 117, 125);"></path>
                                                        <path stroke-linecap="butt" stroke-linejoin="miter"
                                                            fill-opacity="0" stroke-miterlimit="4"
                                                            stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                                                            d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                            style="fill: rgb(108, 117, 125);"></path>
                                                    </g>
                                                </g>
                                                <path style="stroke: rgb(0, 0, 0);"
                                                    d="M 339.265 751.008 L 341.142 792.645 L 857.415 791.74 L 857.415 751.008 L 339.265 751.008 Z">
                                                </path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div v-if="isDialogVisible[slotProps.data.idservicios]" class="dialogexp">
                                        <div><svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                                <defs>
                                                    <clipPath id="__lottie_element_2">
                                                        <rect width="1200" height="1300" x="0" y="0"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g clip-path="url(#__lottie_element_2)">
                                                    <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                                                            <path fill="rgb(13,170,93)" fill-opacity="1"
                                                                d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(13,170,93)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(13,170,93)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg><span>Muy satisfecho</span></div>
                                        <div><svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                                <defs>
                                                    <clipPath id="__lottie_element_22">
                                                        <rect width="1200" height="1300" x="0" y="0"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g clip-path="url(#__lottie_element_22)">
                                                    <g transform="matrix(1,0,0,1,99.739990234375,149.75)" opacity="1"
                                                        style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,500.260009765625,500.25)">
                                                            <path fill="rgb(153,205,155)" fill-opacity="1"
                                                                d=" M0,-500 C134.2270050048828,-500 256.12298583984375,-447.0799865722656 345.95098876953125,-360.9700012207031 C440.8909912109375,-269.9599914550781 500.010009765625,-141.87600708007812 500.010009765625,-0.009999999776482582 C500.010009765625,138.86500549316406 443.364990234375,264.5320129394531 351.9259948730469,355.1440124511719 C261.56500244140625,444.68798828125 137.2270050048828,500 0,500 C-136.88299560546875,500 -260.9410095214844,444.96600341796875 -351.2449951171875,355.8179931640625 C-443.0840148925781,265.1549987792969 -500.010009765625,139.2100067138672 -500.010009765625,-0.009999999776482582 C-500.010009765625,-140.22500610351562 -442.2590026855469,-266.97698974609375 -349.2510070800781,-357.77801513671875 C-259.1189880371094,-445.77099609375 -135.8780059814453,-500 0,-500z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,386.50299072265625,473.343017578125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M57.972999572753906,-17.56999969482422 C68.51899719238281,19.485000610351562 51.09299850463867,57.38600158691406 19.062999725341797,67.08799743652344 C-12.944999694824219,76.78900146484375 -47.44599914550781,54.604000091552734 -57.992000579833984,17.548999786376953 C-68.51799774169922,-19.506999969482422 -51.112998962402344,-57.404998779296875 -19.083999633789062,-67.10700225830078 C12.925999641418457,-76.78800201416016 47.426998138427734,-54.625999450683594 57.972999572753906,-17.56999969482422z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,676.1220092773438,473.343017578125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.97200012207031,-17.56999969482422 C-68.51799774169922,19.485000610351562 -51.09199905395508,57.38600158691406 -19.062999725341797,67.08799743652344 C12.946000099182129,76.78900146484375 47.446998596191406,54.604000091552734 57.99399948120117,17.548999786376953 C68.51899719238281,-19.506999969482422 51.112998962402344,-57.404998779296875 19.083999633789062,-67.10700225830078 C-12.925000190734863,-76.78800201416016 -47.42599868774414,-54.625999450683594 -57.97200012207031,-17.56999969482422z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.909089982509613,0,0,0.909089982509613,600.001708984375,714.9097290039062)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(153,205,155)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.909089982509613,0,0,0.909089982509613,870.9087524414062,714.9097290039062)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(153,205,155)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,404.7409973144531,755.72998046875)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,194.54200744628906,47.52399826049805)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-191.01400756835938,-46.43899917602539 C-192.64599609375,-47.27399826049805 -194.29200744628906,-45.32500076293945 -193.1699981689453,-43.875 C-157.46600341796875,2.246999979019165 -84.44200134277344,47.27299880981445 0.027000000700354576,47.27299880981445 C84.41999816894531,47.27299880981445 157.41799926757812,2.309000015258789 193.14999389648438,-43.779998779296875 C194.29200744628906,-45.250999450683594 192.6199951171875,-47.231998443603516 190.96200561523438,-46.382999420166016 C141.80499267578125,-21.256000518798828 74.4260025024414,0.9279999732971191 0.027000000700354576,0.9279999732971191 C-74.42500305175781,0.9279999732971191 -141.8489990234375,-21.290000915527344 -191.01400756835938,-46.43899917602539z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg><span>Satisfecho</span></div>
                                        <div><svg viewBox="0 0 1200 1300"
                                                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <defs>
                                                    <clippath id="__lottie_element_2">
                                                        <rect width="1200" height="1300" x="0" y="0"></rect>
                                                    </clippath>
                                                </defs>
                                                <g clip-path="url(#__lottie_element_2)">
                                                    <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                                                            <path fill-opacity="1"
                                                                d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z"
                                                                style="fill: rgb(108, 117, 125);"></path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                                style="fill: rgb(108, 117, 125);"></path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                                style="fill: rgb(108, 117, 125);"></path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                                style="fill: rgb(108, 117, 125);"></path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                                                                style="fill: rgb(108, 117, 125);"></path>
                                                        </g>
                                                    </g>
                                                    <path style="stroke: rgb(0, 0, 0);"
                                                        d="M 339.265 751.008 L 341.142 792.645 L 857.415 791.74 L 857.415 751.008 L 339.265 751.008 Z">
                                                    </path>
                                                </g>
                                            </svg><span>Neutral</span></div>
                                        <div><svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                                <defs>
                                                    <clipPath id="__lottie_element_42">
                                                        <rect width="1200" height="1300" x="0" y="0"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g clip-path="url(#__lottie_element_42)">
                                                    <g transform="matrix(1,0,0,1,99.9949951171875,150.0050048828125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,500.0050048828125,499.9949951171875)">
                                                            <path fill="rgb(239,158,161)" fill-opacity="1"
                                                                d=" M0,-499.7449951171875 C146.26400756835938,-499.7449951171875 277.87701416015625,-436.8760070800781 369.2799987792969,-336.6929931640625 C450.3240051269531,-247.86300659179688 499.7550048828125,-129.697998046875 499.7550048828125,-0.009999999776482582 C499.7550048828125,137.9759979248047 443.8039855957031,262.91400146484375 353.3590087890625,353.35699462890625 C262.91400146484375,443.79901123046875 137.9759979248047,499.7449951171875 0,499.7449951171875 C-145.9739990234375,499.7449951171875 -277.3550109863281,437.125 -368.7359924316406,337.2850036621094 C-450.1029968261719,248.38600158691406 -499.7550048828125,129.9770050048828 -499.7550048828125,-0.009999999776482582 C-499.7550048828125,-142.08700561523438 -440.4289855957031,-270.3349914550781 -345.2040100097656,-361.3320007324219 C-255.4759979248047,-447.07598876953125 -133.875,-499.7449951171875 0,-499.7449951171875z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,404.875,753.0499877929688)" opacity="1"
                                                        style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,194.4429931640625,47.499000549316406)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-0.02800000086426735,-47.249000549316406 C-84.3759994506836,-47.249000549316406 -157.33799743652344,-2.308000087738037 -193.052001953125,43.775001525878906 C-194.1929931640625,45.24700164794922 -192.52200317382812,47.22600173950195 -190.86399841308594,46.37799835205078 C-141.73399353027344,21.246000289916992 -74.38800048828125,-0.9279999732971191 -0.02800000086426735,-0.9279999732971191 C74.38600158691406,-0.9279999732971191 141.7760009765625,21.277999877929688 190.9149932861328,46.415000915527344 C192.5469970703125,47.249000549316406 194.1929931640625,45.301998138427734 193.0709991455078,43.85300064086914 C157.38499450683594,-2.244999885559082 84.39900207519531,-47.249000549316406 -0.02800000086426735,-47.249000549316406z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,386.5929870605469,473.41400146484375)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.73300170898438,76.98899841308594)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M57.94300079345703,17.551000595092773 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.073999404907227,-67.06300354003906 C-12.939000129699707,-76.73899841308594 -47.42300033569336,-54.58700180053711 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.486000061035156 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.73899841308594 47.402000427246094,54.58700180053711 57.94300079345703,17.551000595092773z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,676.06396484375,473.41400146484375)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.73400115966797,76.98899841308594)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.8357543945312,436.15179443359375)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(239,158,161)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437133789062,436.15179443359375)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(239,158,161)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg><span>Poco satisfecho</span> </div>
                                        <div><svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                                                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                                                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                                                <defs>
                                                    <clipPath id="__lottie_element_62">
                                                        <rect width="1200" height="1300" x="0" y="0"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g clip-path="url(#__lottie_element_62)">
                                                    <g transform="matrix(1,0,0,1,100.0050048828125,150.0050048828125)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,0.996999979019165,499.9949951171875,499.9949951171875)">
                                                            <path fill="rgb(226,78,103)" fill-opacity="1"
                                                                d=" M0.009999999776482582,-499.7449951171875 C136.44200134277344,-499.7449951171875 260.1210021972656,-445.0450134277344 350.30999755859375,-356.3840026855469 C442.5400085449219,-265.7149963378906 499.7449951171875,-139.531005859375 499.7449951171875,-0.009999999776482582 C499.7449951171875,136.48800659179688 444.99798583984375,260.2200012207031 356.2720031738281,350.4200134277344 C265.61199951171875,442.58599853515625 139.47300720214844,499.7449951171875 0.009999999776482582,499.7449951171875 C-149.1580047607422,499.7449951171875 -283.0889892578125,434.35400390625 -374.6679992675781,330.6759948730469 C-452.5039978027344,242.55799865722656 -499.7449951171875,126.78099822998047 -499.7449951171875,-0.009999999776482582 C-499.7449951171875,-128.78599548339844 -451.0060119628906,-246.2010040283203 -370.9739990234375,-334.8139953613281 C-279.5069885253906,-436.0889892578125 -147.16600036621094,-499.7449951171875 0.009999999776482582,-499.7449951171875z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,402.7510070800781,681.197998046875)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,220.28799438476562,107.28700256347656)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M153.7530059814453,105.4010009765625 C154.29600524902344,106.81600189208984 156.10800170898438,107.03600311279297 156.77699279785156,105.6760025024414 C167.49000549316406,83.89299774169922 220.03799438476562,-38.46200180053711 70.0999984741211,-76.89299774169922 C-48.470001220703125,-107.03700256347656 -194.26400756835938,-7.196000099182129 -219.65699768066406,103.81700134277344 C-220.03799438476562,105.48200225830078 -217.9499969482422,106.59200286865234 -216.64700317382812,105.48799896240234 C-49.48699951171875,-36.2400016784668 118.53099822998047,13.829999923706055 153.7530059814453,105.4010009765625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,386.60699462890625,473.41900634765625)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.73300170898438,77.01000213623047)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M57.94300079345703,17.549999237060547 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.05299949645996,-67.06199645996094 C-12.939000129699707,-76.75900268554688 -47.42300033569336,-54.58599853515625 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.48699951171875 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.75900268554688 47.402000427246094,54.58700180053711 57.94300079345703,17.549999237060547z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(1,0,0,1,676.0809936523438,473.41302490234375)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,69.39299774169922,76.98899841308594)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                                                            </path>
                                                        </g>
                                                        <g opacity="1"
                                                            transform="matrix(1,0,0,1,68.73300170898438,77.01399993896484)">
                                                            <path fill="rgb(0,0,0)" fill-opacity="1"
                                                                d=" M-57.94200134277344,17.549999237060547 C-68.48300170898438,-19.486000061035156 -51.066001892089844,-57.36600112915039 -19.073999404907227,-67.06199645996094 C12.939000129699707,-76.75900268554688 47.422000885009766,-54.58599853515625 57.96200180053711,-17.549999237060547 C68.48300170898438,19.48699951171875 51.08599853515625,57.36600112915039 19.073999404907227,67.06300354003906 C-12.9399995803833,76.75900268554688 -47.402000427246094,54.58700180053711 -57.94200134277344,17.549999237060547z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437744140625,436.1528015136719)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(226,78,103)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.834716796875,436.1528015136719)"
                                                        opacity="1" style="display: block;">
                                                        <g opacity="1"
                                                            transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                                                            <path fill="rgb(226,78,103)" fill-opacity="1"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                            <path stroke-linecap="butt" stroke-linejoin="miter"
                                                                fill-opacity="0" stroke-miterlimit="4"
                                                                stroke="rgb(33,33,33)" stroke-opacity="1"
                                                                stroke-width="0"
                                                                d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg><span>Muy insatisfecho</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <botones-acciones v-else :acciones="col.acciones" :nombredos="slotProps.data[col.field]"
                            :datos="slotProps.data"></botones-acciones>
                        <span style='font-size:small' @click="mostrar(slotProps.data)"
                            v-if="col.header == 'Expediente' && slotProps.data.datos_basicos.n_aviso"><br>{{slotProps.data.datos_basicos.n_aviso}}</span>

                    </div>
                </div>

            </template>
            <template #body="slotProps" v-else>
                <div v-if="col.checkbox" style="display: flex; justify-content: center">
                    <Checkbox @click="marcarCheck(slotProps.data.marcado, slotProps.data)"
                        v-model="slotProps.data.marcado" :binary="true" />
                </div>
                <p v-if="slotProps.data[col.field] && slotProps.data[col.field].html"
                    v-html="slotProps.data[col.field].html"></p>
                <div v-if="slotProps.field=='class'">
                    <div class="row"> <i :class="slotProps.data.class" :style="slotProps.data.style"
                            :title="slotProps.data.tipo"></i><i v-if="slotProps.data.class2"
                            :class="slotProps.data.class2" :style="slotProps.data.style2"
                            :title="slotProps.data.tipo2"></i></div>

                </div>

                <div v-if="slotProps.field=='estado_pedido'">
                    <select class="form-control"
                        @change="cambiarestado(slotProps.data.id, slotProps.data.id_pedido, slotProps.data.estado_pedido)"
                        v-model="slotProps.data.estado_pedido">
                        <option selected v-if="estad==false">{{ slotProps.data.estado_pedido }}</option>
                        <option v-for="estado in Object.values(this.estadospedidos)" :key="estado">{{estado}}</option>
                    </select>
                </div>
                <div v-if="slotProps.field=='estado_pedido2'">
                    <select class="form-control"
                        @change="cambiarestado2(slotProps.data.id, slotProps.data.id_pedido, slotProps.data.estado_pedido)"
                        v-model="slotProps.data.estado_pedido">
                        <option selected v-if="estad==false">{{ slotProps.data.estado_pedido }}</option>
                        <option v-for="estado in Object.values(this.estadospedidos)" :key="estado">{{estado}}</option>
                    </select>
                </div>
                <div
                    v-if="slotProps.field=='carga' && slotProps.data.oculto_usuario!=1 && slotProps.data.permisos.includes('Gestión')">
                    <table class="table table-sm table-bordered" v-if="slotProps.data.cargas">
                        <thead>
                            <tr>
                                <th style="text-align:center;">Carga diaria</th>
                                <th style="text-align:center;">Máx activos</th>
                                <th style="text-align:center;">Jornada</th>
                                <th style="text-align:center;">Ratio activos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.carga_diaria}}({{
                                    slotProps.data.cargas.porcentaje_carga_diaria }})</td>
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.max_activos}}({{
                                    slotProps.data.cargas.max_activos_porcentaje }})</td>
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.periodo_activos}}%</td>
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.ratio_activos}}</td>
                            </tr>
                            <tr v-if="slotProps.data.cargas.alerta!=1">
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.max_carga_diaria_calc}}
                                </td>
                                <td style="background-color:#d9edf7">{{slotProps.data.cargas.activos_calc}}<span
                                        title="Expedientes activos que no tienen marcado 'Finalizar expediente' en 'Trabajos realizados'">({{
                                        slotProps.data.cargas.no_finalizados }})</span></td>
                                <td style="background-color:#d9edf7">-</td>
                                <td style="background-color:#d9edf7">-</td>
                            </tr>
                            <tr v-else>
                                <td style="background-color:#f2dede">{{slotProps.data.cargas.max_carga_diaria_calc}}
                                </td>
                                <td style="background-color:#f2dede">{{slotProps.data.cargas.activos_calc}}<span
                                        title="Expedientes activos que no tienen marcado 'Finalizar expediente' en 'Trabajos realizados'">({{
                                        slotProps.data.cargas.no_finalizados }})</span></td>
                                <td style="background-color:#f2dede">-</td>
                                <td style="background-color:#f2dede">-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <!--    <div v-if="slotProps.field =='fecha_ultima_factura'">
                       <span v-if="!slotProps.data.fecha_ultima_factura">ASD</span>
                </div>-->

                <div v-if="slotProps.field=='facturas'">
                    <table v-if="slotProps.data.facturas && solotienealbaran(slotProps.data.facturas)==false" >
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Factura</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Base</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">IVA</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Total</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Enviada</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Autorizada
                        </th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Cobrada</th>
                        <th style="background:#6c757d;color:white;text-align:center;border:1px solid white">Factura</th>
                        <tr v-for="factura in Object.entries(slotProps.data.facturas)" :key="factura">
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white;width:160px">
                                {{factura[1].numero}}<p style="font-size:10px;margin-bottom:0rem !important" v-if="factura[1].tipo=='albaran'">Albaran</p></td>
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white;width:70px">
                                <span
                                    v-if="factura[1].base[0]!='-'|| factura[1].base.length<=11">&nbsp;</span>{{factura[1].base.replace("&euro","€").replace(";","")}}<span
                                    v-if="factura[1].base[0]!='-'|| factura[1].base.length<=11">&nbsp;</span>
                            </td>
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white;width:70px">
                                <span
                                    v-if="(factura[1].iva[0]!='-'|| factura[1].iva.length<=11)&& factura[1].tipo!='albaran' ">&nbsp;</span><span v-if="factura[1].tipo!='albaran'">{{factura[1].iva.replace("&euro","€").replace(";","")}}</span><span
                                    v-if="(factura[1].iva[0]!='-'|| factura[1].iva.length<=11)&& factura[1].tipo!='albaran'">&nbsp;</span>
                            </td>
                            <td v-if="factura[1].tipo != 'albaran'" @click="sacarlength(factura[1].total)"
                                style="text-align:center;border:1px solid #6c757d ;background:white;width:70px">
                                <span v-if="(factura[1].total[0]!='-' || factura[1].total.length<=11) && factura[1].tipo!='albaran'">&nbsp;</span>
                                {{factura[1].total.replace("&euro","€").replace(";","")}}<span
                                    v-if="(factura[1].total[0] != '-' || factura[1].total.length <= 11 )&& factura[1].tipo != 'albaran'">&nbsp;</span>
                            </td>
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white">
                                <Checkbox :binary="true" v-if="factura[1].bloqueo.enviada!='disabled' && factura[1].tipo!='albaran'"
                                    v-model="factura[1].enviada" trueValue="1"
                                    @click="modificarEnviada(factura[1].enviada,factura[0])" falseValue="0"></Checkbox>
                                <Checkbox :binary="true" v-if="factura[1].bloqueo.enviada=='disabled' && factura[1].tipo!='albaran'"
                                    v-model="factura[1].enviada" trueValue="1" disabled falseValue="0"></Checkbox>

                            </td>

                            <td  v-if="editarfactura == 'si' + factura[0] && factura[1].tipo != 'albaran'">
                                <input @change="modificarfechaaut()" type="date" class="form-control"
                                    v-model="fechaautorizada">

                            </td>
                            <td v-if="editarfactura != 'si' + factura[0] && factura[1].tipo != 'albaran'"
                                @click="idfactura = factura[0]; fechaautorizada = factura[1].autorizada; formatearfecha(); editarfactura = 'si' + idfactura"
                                style="text-align:center;border:1px solid #6c757d ;background:white">
                                <span v-if="factura[1].tipo!='albaran'">{{ factura[1].autorizada }}</span>

                            </td>
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white">
                                <Checkbox :binary="true" v-if="factura[1].bloqueo.cobrada!='disabled' && factura[1].tipo!='albaran'"
                                    v-model="factura[1].cobrada" @click="modificarCobro(factura[1].cobrada,factura[0])"
                                    trueValue="1" falseValue="0"></Checkbox>
                                <Checkbox :binary="true" v-if="factura[1].bloqueo.cobrada =='disabled' && factura[1].tipo!='albaran'"
                                    v-model="factura[1].cobrada" disabled trueValue="1" falseValue="0"></Checkbox>
                            </td>
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;border:1px solid #6c757d ;background:white"><a
                                    @click="mostrarPdf(factura[1].url_completa_factura_pdf)" style="color:black"
                                    href="#"><i class="fas fa-file-invoice"></i></a></td>
                        </tr>
                    </table>
                </div>
                <div style="padding-bottom:16px"
                    v-if="slotProps.field == 'fecha_ultima_factura' && slotProps.data.fecha_ultima_factura && !isNaN(new Date(slotProps.data.fecha_ultima_factura))">
                    {{ new Date(slotProps.data.fecha_ultima_factura).toLocaleDateString('es-ES', { year: 'numeric',
                    month: '2-digit', day: '2-digit' }) }}
                </div>
                <div style="padding-bottom:16px;" :title="slotProps.data.fecha_espera_usuario"
                    v-if="slotProps.field == 'fecha_espera' && slotProps.data.fecha_espera && !isNaN(new Date(slotProps.data.fecha_espera))">
                    <span class="badge" :style="'padding:2.5px;background:'+slotProps.data.fecha_espera_color"> {{ new
                        Date(slotProps.data.fecha_espera).toLocaleDateString('es-ES', {
                        year: 'numeric',
                        month: '2-digit', day: '2-digit'
                        }) }}</span>
                </div>
                <div style="padding-bottom:16px"
                    v-if="slotProps.field == 'fecha_eliminacion' && slotProps.data.fecha_eliminacion && !isNaN(new Date(slotProps.data.fecha_eliminacion))">
                    {{ new Date(slotProps.data.fecha_eliminacion).toLocaleDateString('es-ES', { year: 'numeric', month:
                    '2-digit', day: '2-digit' }) }}
                </div>
                <div class="row" v-if="slotProps.field=='facturapresupuesto'">
                    <button type="button" :class="'btn btn-sm btn-default factura '+slotProps.data.color_factura"
                        style="font-size:xx-small" title="Factura">&nbsp;</button>
                    <button type="button" :class="'btn btn-sm btn-default '+slotProps.data.color_presupuesto"
                        style="font-size:xx-small" title="Presupuesto">&nbsp;</button>
                </div>

                <div v-else-if="slotProps.field != 'carga'">
                    <div v-if="slotProps.field !='fecha_ultima_factura'">
                        <div v-if="slotProps.field !='fecha_eliminacion'">
                            <div v-if="slotProps.field !='fecha_espera'">
                                <div v-if="slotProps.field !='estado_pedido'">
                                    <div v-if="slotProps.field !='estado_pedido2'">
                                        <div v-if="slotProps.field!='class'">
                                            <div v-if="slotProps.field">
                                                <p v-if="slotProps.field!='facturas'"
                                                    v-html="decode_utf8(slotProps.data[col.field])"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #filter="{filterModel,filterCallback}" v-if="filters[col.field]">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                    class="p-column-filter" :placeholder="col.header" />
            </template>
        </Column>
        <template #expansion>
            <div class="p-4">
                <h5>Orders for</h5>
            </div>
        </template>
        <OverlayPanel ref="op" style="width: 600px;margin-left:8em">
            <strong>{{ datosResumen.datos_basicos.asegurado.nombre }}</strong>
            <p>{{datosResumen.datos_basicos.asegurado.direccion}}</p>
            <p>{{ datosResumen.datos_basicos.asegurado.cp }} {{ datosResumen.datos_basicos.asegurado.provincia }}</p>
            <p>{{ datosResumen.datos_basicos.asegurado.telefonos[0] + " - " +
                datosResumen.datos_basicos.asegurado.telefonos[1] + " - " +
                datosResumen.datos_basicos.asegurado.telefonos[2] }}</p>
            <!--<p>{{ datosResumen.datos_basicos.danos }}</p>-->
        </OverlayPanel>
    </DataTable>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import OverlayPanel from 'primevue/overlaypanel';
import BotonesAcciones from './BotonesAcciones.vue'
import { defineAsyncComponent } from 'vue';
import Checkbox from 'primevue/checkbox';
import { PwgsApi } from '../services/PwgsApi';
import Dialog from 'primevue/dialog';

export default {
    emits: [
        'quieroDatos'
    ],
    props: [
        'tramitadores',
        'avisosConFiltro',
        'trabajos',
        'solicitudes',
        'asignaciones',
        'columnas',
        'obtencionDatos',
        'filtros',
        'datos',
        'filtrosBuscador',
        'total',
        'botones',
        'orden',
        'botoncrear',
        'urgente',
        'nolazy',
        'num',
        'facturacion',
        'numdatosdefecto',
        'page',
        'servicios',
        'exportar',
        'estadospend'
    ],
    components: {        
        'botones-acciones': BotonesAcciones,
        Checkbox,
        OverlayPanel,
        Dialog,
        Dropdown,
    },
    data() {
        return {
            nombrefiltrado:'',
            pendienteselec:'',
            tramitadorSeleccionado:'',
            checkboxGeneral:false,
            currentPage:2,
            mostrarExplicacion:false,
            explicacionSatis:'',
            datosResumen:'',
            infoamostrar:'',
            mostrarRes:[],
            isDialogVisible: [],
            loading: false,
            filters: this.$props.filtros,
            lazyParams: {},
            esnuevo: false,
            numDatos:15,
            editarfactura: 'no',
            crears: 'Crear',
            refrescars: 'Refrescar', 
            exportars: 'Exportar',
            filtrarpor: '',
            fechaautorizada:'',
            clase: 'p-datatable-sm',
            filtrosdos: {
                'global': { value: '', matchMode: 'contains' },
            },
            mostrarspan: false,
            estadospedidos: '',
            estad: false,
            stadopedido:'',
            first:0,
            last: 15,
            idfactura: '', 
            modificarfactura:0,
        }
    },
    methods: { 
        proveedorPulsado(id){
            this.$router.push({
                name: 'Proveedor',
                params: {
                    id: id
                }
            });
        },
        llamar (tlf) {
            window.open("tel:" + tlf);
        },
        email (id) {
            this.$emit('email', id);      
        },
        onKeydownHandler(event){
            if (event.key === " ") {
                // Agregar espacio manualmente al modelo
                this.nombrefiltrado += " ";
            }
        },
        solotienealbaran(facturas) {
            let soloalbaran = true;

         /*   "factura in Object.entries(slotProps.data.facturas)" :key="factura">
                            <td v-if="factura[1].tipo != 'albaran'" style="text-align:center;bord"*/

            console.log("factura", Object.entries(facturas));


            for (let i = 0; i < Object.entries(facturas).length;i++) {
                if (Object.entries(facturas)[i][1].tipo != 'albaran') {
                    soloalbaran = false;
              }  
            }
            return soloalbaran;

            
        },
        formatearfecha() {
            this.fechaautorizada = this.fechaautorizada[6] + this.fechaautorizada[7] +   this.fechaautorizada[8] + this.fechaautorizada[9] + '-' + this.fechaautorizada[3] + this.fechaautorizada[4] + '-' + this.fechaautorizada[0] + this.fechaautorizada[1]
        },
        marcarCheck(marcado, datos){
            console.log('entracheck',marcado, datos);
            if(marcado){
                this.$emit('desmarcar', datos);
            }else{
                this.$emit('marcar', datos);
            }
        },
        sacarlength(valor) {
   console.log("length",valor.length);     
    },
        explicacion(data){
            this.explicacionSatis = data.explicacion_satisfaccion_ia_servicio;
            console.log('explicacionsatis', data);
            this.mostrarExplicacion = !this.mostrarExplicacion;
        },
        decode_utf8(s) {
            try{
            return decodeURIComponent(escape(s));
            }
            catch(error){
                console.log(error);
                return s;
            }
        },
        infoevento(event, field, datos){
            if (field == "codigo" && this.$refs.op.visible == false) {       
                console.log("opref", this.$refs.op);  
                this.datosResumen = datos;         
                this.$refs.op.show(event);
            }
        },
        cargarListener() {
           
           
            
            setTimeout(() => {
                let fila = document.getElementsByClassName("p-row-even");
                console.log("fila", fila);
                let filados = document.getElementsByClassName("p-row-odd");
                for (let i = 0; i < fila.length; i++) {
                    console.log("par", fila[i]);
                    fila[i].addEventListener('mouseenter', this.closeInfo);
                }

                for (let j = 0; j < filados.length; j++) {
                    console.log("impar", filados[j]);
                    filados[j].addEventListener('mouseenter', this.closeInfo);
                }      
            }, 5000); 
            
           
            
            //console.log("filados", filados);
        },
        destruirListener() {

            setTimeout(() => {

                let fila = document.getElementsByClassName("p-row-even");
                //console.log("fila", fila);
                let filados = document.getElementsByClassName("p-row-odd");
                for (let i = 0; i < fila.length; i++) {
                    console.log("par", fila[i]);
                    fila[i].removeEventListener('mouseenter', this.closeInfo());
                }

                for (let j = 0; j < filados.length; j++) {
                    console.log("impar", filados[j]);
                    filados[j].removeEventListener('mouseenter', this.closeInfo);
                } 
            }, 5000); 


        },
        closeInfo() {
           // console.log("123hola", document.querySelectorAll(":hover"));
            this.$refs.op.hide();
        },
        async cambiarestado(idservicio, idpedido, estadop) {
            this.estad = true;
            const api = new PwgsApi();
           let subidadatos = { estado: estadop };
           await api.put('servicios/' + idservicio + '/pedidos/' + idpedido, subidadatos);
        },
        async cambiarestado2(idservicio, idpedido, estadop) {
            this.estad = true;
            const api = new PwgsApi();
           let subidadatos = { estado: estadop };
           await api.put('compras-pwgs/' + idpedido + '/estado-pedido', subidadatos);
        },
        cambios(){
            console.log('accionesref',this.$refs.accionesref);
            if(this.$refs.accionesref){
                for(let ref of this.$refs.accionesref){
                    ref.cambio();
                }
            }
        },
        async cargar() {
            this.destruirListener();
           this.loading = true;
           console.log('lzparams',this.lazyParams);
            this.$emit('quieroDatos', this.lazyParams);
            console.log('carga');  
            this.cargarListener();          
        },
        async mostrarpedidos() {
            const api = new PwgsApi();
            this.estadospedidos = await api.get('estados-pedidos');
            console.log("slot", this.estadospedidos);
        },
        mostrar(datos){
            console.log("mostrar",datos);
        },
        async onPage(event) {
            this.lazyParams = event;
            if(!this.lazyParams.sortOrder && this.asignaciones){
                this.lazyParams.sortOrder = 1;
            }
            if(!this.lazyParams.sortOrder && this.trabajos){
                this.lazyParams.sortOrder = 1;
            }
            this.cargar();
        },
        async onSort(event) {
            //console.log("eventooo",event, this.solicitudes);
            this.lazyParams = event;
            if (this.lazyParams.sortField =="dias_apertura"){
                this.lazyParams.sortField = "fecha_crear_servicio";
            }
            if (this.lazyParams.sortField == "dias_sin_actualizar") {
                this.lazyParams.sortField = "fecha_actualiza";
            }
            if (this.lazyParams.sortField == "fecha_hora" && this.solicitudes) {
                this.lazyParams.sortField = "id_mensaje_cache";
            }else if(this.lazyParams.sortField == "fecha_hora" && this.asignaciones){
                this.lazyParams.sortField = "fecha_crear_servicio";
            }else if(this.lazyParams.sortField == "fecha_hora" && this.trabajos){
                this.lazyParams.sortField = "";
            }
            /*else{
                this.lazyParams.sortField = "fecha_actualiza_mensaje_cache";
            }*/
            this.cargar();
        },
     
        mostrarPdf(urlfactura) {
            //let direccionglobal = localStorage.getItem('direccion');
            let auxurlpdf = urlfactura;

            window.open(auxurlpdf,'_blank')
        },
        async modificarEnviada(enviada, idfactura) {
      ///modulos/pwgsapi/index.php/facturas/:id_factura/enviada-factura
           const api = new PwgsApi();
           let subidadatos = {}
           if (enviada == 0) {
               subidadatos.enviada = 'SI';  
           }
           else{
            subidadatos.enviada = ''
           }
            await api.put('facturas/' + idfactura + '/enviada-factura', subidadatos);
        },
        async modificarCobro(cobrada, idfactura) {
            ///modulos/pwgsapi/index.php/facturas/:id_factura/modificar-factura
            const api = new PwgsApi();
            let subidadatos = {}
            if (cobrada == 0) {
                subidadatos.cobrada = 'SI';
            }
            else {
                subidadatos.cobrada = ''
            }
            await api.put('facturas/' + idfactura + '/modificar-factura', subidadatos);
        },
        async onFilter() {
            this.lazyParams.filters = this.filters;
            this.cargar();
        },
        esColumnaPersonalizada(col) {
            return col.formato || col.acciones || col.componente;
        },
        esColumnaProveedor(col){
            return col.proveedor;
        },
        componenteDinamico(ruta) {
            return defineAsyncComponent(() => import(`@/${ruta}`));
        },
        exportCSV() {
            this.$refs.tabla.exportCSV();
        },
        refrescar() {
            window.location.reload();
        },
        crear() {
            console.log('crear', this.$route.name);
            if(this.$route.name == "Compañías"){
                this.$router.push({ name: ("Crear Compañía"), params: { id: 0 } });
            }
            if(this.$route.name == "Mensajes Predefinidos"){
                this.$router.push({ name: ("Crear Mensajes Predefinidos")});
            }
            else{
                this.$router.push({ name: ("Crear " + this.$route.name), params: { id: 0 } });
        }    
        },
        mostrarResumen(id){
            this.mostrarRes[id] = true;
        },
        ocultarResumen(id){
            this.mostrarRes[id] = false;
        },
        showDialog(id) {
            this.isDialogVisible[id] = true;
        },
        hideDialog(id) {
            this.isDialogVisible[id] = false;
        },
        async modificarfechaaut() {
            if (this.editarfactura == 'si' + this.idfactura) {
                const api = new PwgsApi();
                let subidadatos = { fecha: this.fechaautorizada[8] + this.fechaautorizada[9] +'/'+this.fechaautorizada[5] + this.fechaautorizada[6] +'/'+this.fechaautorizada[0] + this.fechaautorizada[1] + this.fechaautorizada[2] + this.fechaautorizada[3] }
               
                try {
                    await api.put('facturas/' + this.idfactura + '/fecha-autorizacion', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Fecha cambiada correctamente', life: 2000 });
                    this.editarfactura = 'no';
                    this.modificarfactura = 0;
                    this.cargar();


                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
                    this.editarfactura = 'no';
                    this.modificarfactura = 0;


                }
            }
        }
    },
    activated() {
        this.cargarListener(); 
    },
    watch: {
        fechaautorizada() {
            console.log("idfactura", this.idfactura);
            console.log("ff", this.fechaautorizada);
            if (this.modificarfactura > 0) {
                console.log("idfactura", this.idfactura);
                this.modificarfechaaut();
            }
        },
        nombrefiltrado(value){
            this.$emit('cambianombre', value);
        },
        $route() {
            this.$refs.op.hide();
 
        },
        tramitadorSeleccionado(value){
            if(value){
                this.lazyParams.id_tramitador = value;
            }else{
                delete(this.lazyParams.id_tramitador);
            }
            this.$emit('quieroDatos', this.lazyParams);
        },
        pendienteselec(value){
            if(value){
                this.lazyParams.id_esp_pend = value;
            }else{
                delete(this.lazyParams.id_esp_pend);
            }
            this.$emit('quieroDatos', this.lazyParams);
        },
        checkboxGeneral(value){
            if(value){
                this.$emit('marcarTodos');
            }else{
                this.$emit('desmarcarTodos');
            }
            console.log('value',value);
        },
        datos() {
            this.loading = false;
          /*  setTimeout(() => {
                this.cerrardialogo();
            }, 2000);*/

                },
        numdatosdefecto(){
            console.log('entradrefecto', this.numdatosdefecto);
            this.numDatos = this.numdatosdefecto[0];
        },
        exportar(){
                        this.$refs.tabla.exportCSV();

        }
    },

    async mounted() { 
        console.log('entramounted');
        if(this.servicios && this.num){
            console.log('entranum', this.num, this.page);
            this.numDatos = this.num;
            if(this.num && this.page){
                this.first = this.num * this.page;
                this.last = this.first+this.num;
            }
            this.lazyParams = {
                first: this.first,
                rows: this.numDatos,
                sortField: "",
                sortOrder: "1",
                filters: this.$props.filtros,
            };
        }else{
            this.lazyParams = {
                first: this.first,
                rows: this.$refs.tabla.rows,
                sortField: "",
                sortOrder: "1",
                filters: this.$props.filtros,
            };
        }
        
        this.esnuevo=false;
        this.cargar();
        this.filtrarpor = '';
        this.crears = this.$t("general.crear");
        this.refrescars = this.$t("general.refrescar");
        this.exportars = this.$t("general.exportar");
        this.filters = this.$props.filtros;
        this.mostrarpedidos();
    },
}
</script>
<style>
.codigo {
    cursor: pointer !important;
}
.acciones {
    cursor: pointer !important;
}
.p-button{
    visibility: visible !important;
}
.icono{
    width:max-content;
}
.satisf {
  position: relative;
  display: inline-block;
}

.dialogexp {
  position: absolute;
  bottom: 95%;
  left: 260%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
}
.resumen{
    width: 350px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.resumcont:hover .resumen {
    display: grid;
}
.factura{
background-color: #6c757d !important;
}
.FondoGrisOscuro{
background-color: #6c757d !important;
}
.FondoVerde{
background-color: #28a745 !important;
}
.FondoNaranja{
background-color: #f97316 !important;
}
.FondoRojo{
background-color:#e20909 !important;
}
.FondoAmarillo{
background-color: #ffcc00 !important;
}

</style>
